import discordIcon from "./img/discord.svg";
import githubIcon from "./img/github.svg";
import telegramIcon from "./img/telegram.svg";
import twitterIcon from "./img/twitter.svg";

export const WALLET_ONELINK = "https://onelink.to/t3sae5";
export const FARMS_LINK = "https://farms.liquidswap.com/";

export const KANA_WIDGET_URL = "/kana-widget";
export const OFT_WIDGET_URL = "/oft";

export const headerLinksList = [
  {
    label: "Swap",
    items: [
      {
        label: "Swap",
        name: "swap",
        url: "https://liquidswap.com/#/",
        to: { name: "swap" },
        icon: "earn-link earn-link_type_swap-wide",
      },
      {
        icon: "earn-link earn-link_type_kana-swap",
        label: "Cross-Chain Swap",
        name: "kana",
        url: KANA_WIDGET_URL,
        to: { name: "kana-widget" },
      },
    ],
  },
  {
    label: "Earn",
    items: [
      {
        label: "Pools",
        name: "pools",
        url: "https://liquidswap.com/#/pools",
        to: { name: "pools" },
        icon: "earn-link earn-link_type_pools",
      },
      {
        label: "Farms",
        name: "stakes",
        url: FARMS_LINK,
        to: { name: "stakes" },
        icon: "earn-link earn-link_type_farms",
      },
      {
        label: "Staking",
        name: "aptos-staking",
        url: "https://liquidswap.com/#/aptos-staking",
        to: { name: "aptos-staking" },
        icon: "earn-link earn-link_type_staking",
      },
    ],
  },
  {
    label: "Bridge",
    items: [
      {
        label: "LayerZero",
        name: "layerzero",
        url: "/",
        to: { name: "layerzero" },
        icon: "earn-link earn-link_type_layerzero",
      },
      {
        label: "Wormhole",
        name: "wormhole",
        url: "/wormhole-widget",
        to: { name: "wormhole" },
        icon: "earn-link earn-link_type_wormhole",
      },
    ],
  },
  {
    desktopIcon: "stats",
    label: "Stats",
    name: "stats",
    url: "https://liquidswap.com/#/stats",
    to: { name: "stats" },
  },
];

export const linksList = [
  [
    {
      label: "Pontem Network",
      url: "https://pontem.network/",
    },
    {
      label: "Liquidswap DEX",
      url: "https://pontem.network/liquidswap",
    },
    {
      label: "Move Code Playground",
      url: "https://pontem.network/move-code-playground",
    },
  ],
  [
    {
      label: "Resource Center",
      url: "https://pontem.network/resource-center",
    },
    {
      label: "Documentation",
      url: "https://docs.liquidswap.com",
    },
    {
      label: "Get Started",
      url: "https://pontem.network/get-started",
    },
  ],
  [
    {
      label: "Ambassador Program",
      url: "https://pontem.network/ambassador-program",
    },
    {
      label: "Privacy Policy",
      url: "https://pontem.network/privacypolicy",
    },
  ],
];

export const socialMenuItems = [
  {
    icon: "pi pi-info-circle",
    label: "Learn more",
    url: "https://home.liquidswap.com",
  },
  {
    icon: "pi pi-book",
    label: "Docs",
    url: "https://docs.liquidswap.com",
  },
  {
    icon: "pi pi-discord",
    label: "Discord",
    url: "https://discord.com/invite/44QgPFHYqs",
    target: "_blank",
  },
  {
    icon: "pi pi-comments",
    label: "Feedback for partners",
    url: "https://form.typeform.com/to/ggYvlrgj",
    target: "_blank",
  },
  {
    icon: "pi pi-telegram",
    label: "Telegram Community",
    url: "https://t.me/pontemnetworkchat",
    target: "_blank",
  },
];

export const socialLinks = [
  {
    icon: githubIcon,
    label: "Github",
    url: "https://github.com/pontem-network",
  },
  {
    icon: twitterIcon,
    label: "Twitter",
    url: "https://twitter.com/PontemNetwork",
  },
  {
    icon: telegramIcon,
    label: "Telegram",
    url: "https://t.me/pontemnetworkchat",
  },
  {
    icon: discordIcon,
    label: "Discord",
    url: "https://discord.com/invite/44QgPFHYqs",
  },
];

export const AIRDROP = "https://airdrop.liquidswap.com/";
