import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  Header,
  Footer,
  Layout,
  StargateWidget,
  KiwiBrowserBanner,
} from "./components";
import { useLocalStorage } from "./hooks/useLocalStorage";
import WormholeWidget from "./components/wormhole-widget";
import KanaWidget from "./components/kana-widget";

function App() {
  function onCloseKiwi() {
    setStorageKiwiValue("false");
  }

  const [storageKiwiValue, setStorageKiwiValue] = useLocalStorage(
    "showKiwiBrowserBanner",
    "true"
  );

  return (
    <BrowserRouter>
      <div className="App">
        <Layout>
          <>
            <Header />
            <Routes>
              <Route path="/" element={<StargateWidget />} />
              <Route path="/kana-widget" element={<KanaWidget />} />
              <Route path="/wormhole-widget" element={<WormholeWidget />} />
            </Routes>
            <Footer />
            {storageKiwiValue === "true" ? (
              <KiwiBrowserBanner onCloseClick={onCloseKiwi} />
            ) : undefined}
          </>
        </Layout>
      </div>
    </BrowserRouter>
  );
}

export default App;
